import { useLoaderData, useLocation, useNavigate } from 'react-router';

import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { QueryPagination } from '@/components/QueryPagination';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import QueryOffersFilteForm from './components/QueryOffersFilterForm';
import { getOffersTableColumns, items, Offer, offersTableColumns } from './utils';
import { CompetencyGroup } from '@backend/model/competencies/types';
import { Subject } from '@backend/model/subjects';
import { SyntheticEvent } from 'react';

export const OFFERS_PAGE_SIZE = 5;

export const OFFERS_DEFAULT_ORDER_BY = 'id:desc';

export function OffersListPage() {
  // const { result: items, total } = useLoaderData() as ServiceResult<Offer[]>;
  const { subjects, groups } = useLoaderData() as {
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const total = 2;

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: Offer) => () => {
    navigate([location.pathname, clicked.id, 'edit'].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Offer>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), OFFERS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  const getHandleViewOffer = (offerId: number) => (ev: SyntheticEvent | Event) => {
    ev.stopPropagation();
    navigate(`/${['students', 'offers', offerId].join('/')}`);
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Angebote</h2>
      <div className="mb-6 flex flex-col-reverse justify-end xs:flex-row">
        <QueryOffersFilteForm className="grow flex-col xs:flex-row" subjects={subjects} groups={groups} />
        <Button link={[location.pathname, 'new'].join('/')} className="mb-4 sm:mb-0 sm:ml-4">
          Neues Angebot
        </Button>
      </div>
      {!items.length ? (
        <p>Keine Angebote gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={getOffersTableColumns({ getHandleViewOffer })}
            rows={items}
            className="mb-8"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={OFFERS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}

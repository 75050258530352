import { useLoaderData, useLocation, useNavigate } from 'react-router';

import type { Material } from '@backend/model/materials/types';
import type { ServiceResult } from '@backend/service';
import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { QueryPagination } from '@/components/QueryPagination';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import QueryMaterialsFilterForm from './components/QueryMaterialsFilterForm';
import { MATERIALS_DEFAULT_ORDER_BY, MATERIALS_PAGE_SIZE } from './loaders';
import { materialTableColumns } from './utils';
import Subline from '@/components/common/Subline';

export function MaterialsListPage() {
  const { result: items, total } = useLoaderData() as ServiceResult<Material[]>;

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: Material) => () => {
    navigate([location.pathname, clicked.id, 'edit'].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Material>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), MATERIALS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  return (
    <div>
      <Subline count={total}>Materialien</Subline>
      <div className="mb-6 flex flex-col-reverse justify-end xs:flex-row">
        <QueryMaterialsFilterForm className="grow flex-col xs:flex-row" />
        <Button link={[location.pathname, 'new'].join('/')} className="mb-4 sm:mb-0 sm:ml-4">
          Neues Material
        </Button>
      </div>
      {!items.length ? (
        <p>Keine Materialien gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={materialTableColumns}
            rows={items}
            className="mb-8"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={MATERIALS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}

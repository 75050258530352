import type { Competency, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { ChangeEvent, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { Dropdown } from '@/components/common/form/Dropdown';
import { EntityForm } from '@/components/common/form/EntityForm';
import { Label } from '@/components/common/form/Label';
import { InputField } from '@/components/common/form/InputField';

type Props = {
  competency?: Partial<Competency>;
  subjects: Subject[];
  groups: CompetencyGroup[];
  onSubmit: SubmitHandler<Competency>;
  onCancel?: () => void;
};

export default function CompetencyForm(props: Props) {
  const { onCancel, onSubmit, competency, subjects, groups } = props;

  const [filteredGroups, setFilteredGroups] = useState<CompetencyGroup[]>(groups);

  const onSubjectIdChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const subjectId = event.target.value;
    if (subjectId) {
      setFilteredGroups(groups.filter((g) => g.subjectId === Number(subjectId)));
      return;
    }
    setFilteredGroups(groups);
  };

  return (
    <EntityForm entity={competency} onCancel={onCancel} onSubmit={onSubmit} showId>
      <Label name="Fach">
        <Dropdown
          name="subjectId"
          fieldOptions={{ required: 'Fach wählen' }}
          entries={[{ value: '', label: 'Bitte wählen ...' }, ...subjects.map((s) => ({ value: s.id, label: s.label }))]}
          onChange={onSubjectIdChange}
        />
      </Label>
      <Label name="Kompetenz-Gruppe">
        <Dropdown
          name="groupId"
          fieldOptions={{ required: 'Gruppe wählen' }}
          entries={[{ value: '', label: 'Bitte wählen ...' }, ...filteredGroups.map((g) => ({ value: g.id, label: g.label }))]}
        />
      </Label>
      <Label name="Name">
        <InputField name="label" placeholder="du ..." />
      </Label>
      <Label name="Beschreibung">
        <InputField name="description" />
      </Label>
      <Label name="Ab Klassenstufe">
        <InputField name="fromClass" type="number" min={1} max={10} step={1} required />
      </Label>
      <Label name="Bis Klassenstufe">
        <InputField name="toClass" type="number" min={1} max={10} step={1} required />
      </Label>
    </EntityForm>
  );
}

import type { User } from '@backend/model/users/types';
import Avatar from '@/components/Avatar';
import { Icon } from '@/components/Icon';
import { tw, variants } from 'classname-variants';

type StudentTileProps = {
  student: User;
  selected?: boolean;
  onClick?: () => void;
};

export function StudentTile(props: StudentTileProps) {
  const { selected: selected, student, onClick } = props;

  const classes = variants({
    base: tw`flex items-center gap-2 border-2 border-black p-4 rounded transition-al`,
    variants: {
      onClick: {
        true: tw`cursor-pointer`,
      },
      selected: {
        false: tw`hover:bg-fuxs-orange-light`,
        true: tw`bg-ok-green hover:opacity-80`,
      },
    },
  });

  return (
    <div className={classes({ onClick: !!onClick, selected })} onClick={onClick}>
      <Avatar user={student} />
      <div className="ml-2">{student.displayName}</div>
      <div className="flex-grow" />
      <Icon name="chevron_right" />
    </div>
  );
}

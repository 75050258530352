import type { Subject } from '@backend/model/subjects';
import { Dropdown } from '@/components/common/form/Dropdown';
import { QueryFilterForm } from '@/components/common/form/QueryFilterForm';
import RangeSliderField from '@/components/common/form/RangeSliderField';
import { InputField } from '@/components/common/form/InputField';

export type GroupsFilter = {
  search?: string;
  subjectId?: number;
};

type Props = {
  subjects: Subject[];
  className?: string;
};

export default function GroupsFilteForm(props: Props) {
  const { className, subjects } = props;

  return (
    <QueryFilterForm className={className}>
      <InputField name="search" placeholder="Suche ..." />
      <Dropdown name="subjectId" entries={[{ value: '', label: 'Fach ...' }, ...subjects.map((s) => ({ value: s.id, label: s.label }))]} />
      <RangeSliderField
        className="w-full"
        name="classLevel"
        min={1}
        max={10}
        step={1}
        minStepsBetweenThumbs={1}
        defaultValue={[1, 10]}
        renderThumbValue
      >
        Klassenstufe
      </RangeSliderField>
    </QueryFilterForm>
  );
}

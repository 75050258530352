import { ReactNode, useEffect } from 'react';
import { DeepPartial, DefaultValues, FieldValues, useForm } from 'react-hook-form';

import { Form } from './Form';

export type FilterFormChangeHandler<T> = (value: DeepPartial<T>) => void;

type FilterFormProps<T> = {
  className?: string;
  filter?: DefaultValues<T>;
  onChange?: FilterFormChangeHandler<T>;
  children: ReactNode;
};

export function FilterForm<T extends FieldValues>(props: FilterFormProps<T>) {
  const { children, className, filter, onChange } = props;

  const form = useForm<T>({ defaultValues: filter });
  const { handleSubmit, watch } = form;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log('FilterForm.watch', name, type, value);
      if (name && !name.endsWith('[]') && (type === 'change' || type === undefined)) {
        onChange?.(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  useEffect(() => {
    form.reset(filter);
  }, [filter]);

  const submitHandler = handleSubmit(() => {});

  return (
    <Form className={`flex flex-row gap-4 ${className}`} onSubmit={submitHandler} {...form}>
      {children}
    </Form>
  );
}

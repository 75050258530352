import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router';

import { useUser } from '@/UserProvider';

import { User } from '@backend/model/users/types';
import Message from '@/components/common/Message';
import { TabNav, TabNavPropsItem } from '@/components/TabNav';
import TopBar from '@/components/TopBar';
import { StudentsSwitcher } from './components/StudentsSwitcher';
import Headline from '@/components/common/Headline';

export function StudentsPage() {
  const user = useUser();
  const { studentId, offerId } = useParams();
  const students = (useLoaderData() as User[]) || undefined;

  const tabNav: TabNavPropsItem[] = [
    {
      label: 'Arbeitspläne',
      link: studentId ? `/students/plans/${studentId}` : '/students/plans',
    },
    {
      label: 'Angebote',
      link: offerId ? `/students/offers/${offerId}` : '/students/offers',
    },
    {
      label: 'Tagesberichte',
      link: studentId ? `/students/reports/${studentId}` : '/students/reports',
    },
    {
      label: 'Kompetenzen',
      link: studentId ? `/students/competencies/${studentId}` : '/students/competencies',
    },
  ];

  const navigate = useNavigate();

  const selectStudent = (studentId: number) => {
    const parts = location.pathname.split('/');
    navigate([...parts.slice(0, 3), studentId, ...parts.slice(4)].join('/'));
  };

  // NOTE: Only for showing the prototype warning
  const location = useLocation();
  const isActive = (prefix: string, exact = false) => {
    const uri = location.pathname;
    if (exact) return uri === prefix;
    return uri.startsWith(prefix);
  };
  const isPrototype = isActive('/students/offers');

  return (
    <div>
      <TopBar user={user} isPrototype={isPrototype} />

      <div className="mx-auto flex h-full flex-col p-4">
        <Message />

        <div className="flex flex-col justify-between md:flex-row md:items-center">
          <Headline>Schülerinnen & Schüler</Headline>
          {studentId && <StudentsSwitcher students={students} selection={{ studentId }} onChange={selectStudent} className="mb-8" />}
        </div>
        <TabNav className="mb-6 flex-col xs:flex-row" items={tabNav} />
        <Outlet />
      </div>
    </div>
  );
}

import { cn } from '@/lib/utils';

export default function Subline(props: { className?: string; children: React.ReactNode; count?: number }) {
  const { className, children, count } = props;

  return (
    <h2 className={cn('mb-4 text-2xl font-bold flex items-center gap-2', className)}>
      {children}
      {count != undefined && <span className="text-gray-500 text-sm">({count})</span>}{' '}
    </h2>
  );
}

import { DeepPartial } from 'react-hook-form';

import { MaterialType, type MaterialsFilter } from '@backend/model/materials/types';
import { FilterForm } from '@/components/common/form/FilterForm';
import { InputField } from '@/components/common/form/InputField';
import { Dropdown } from '@/components/common/form/Dropdown';
import { getMaterialTypeLabel } from '../utils';

type Props = {
  className?: string;
  filter: MaterialsFilter;
  onChange?: (value: DeepPartial<MaterialsFilter>) => void;
};

export function MaterialsFilterForm(props: Props) {
  const { className, filter, onChange } = props;

  return (
    <FilterForm className={className} onChange={onChange} filter={filter}>
      <MaterialsFilterFormChildren />
    </FilterForm>
  );
}

export function MaterialsFilterFormChildren() {
  const materialTypes = Object.values(MaterialType).map((type) => ({
    value: type,
    label: getMaterialTypeLabel(type),
  }));

  return (
    <>
      <InputField name="search" placeholder="Suche ..." clearable />
      <Dropdown name="type" fieldOptions={{ required: false }} entries={[{ value: '', label: 'Typ ...' }, ...materialTypes]} />
    </>
  );
}

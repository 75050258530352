import { JSX } from 'react';
import { NavLink } from 'react-router-dom';

import { cn } from '@/lib/utils';

export type ButtonProps = JSX.IntrinsicElements['button'] & {
  link?: string;
  href?: string;
  target?: string;
};

export function Button(props: ButtonProps) {
  const { className, href, link, target, type, children, ...rest } = props;

  const classNames = cn(
    'flex items-center h-10 px-3 py-1 border-2 rounded transition-all whitespace-nowrap',
    'border-gray-500 bg-white hover:bg-fuxs-orange-light',
    'ring-offset-fuxs-orange-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-fuxs-orange-light focus-visible:ring-offset-2',
    className
  );

  if (href) {
    return (
      <a href={href} target={target} className={classNames}>
        {children}
      </a>
    );
  }

  if (link) {
    return (
      <NavLink to={link} target={target} className={classNames}>
        {children}
      </NavLink>
    );
  }

  const renderButton = () => (
    <button className={classNames} type={type || 'button'} {...rest}>
      {children}
    </button>
  );

  return renderButton();
}

import type { CompetenciesFilter, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { ReactNode, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import { Dropdown } from '@/components/common/form/Dropdown';
import { FilterForm } from '@/components/common/form/FilterForm';
import { InputField } from '@/components/common/form/InputField';
import RangeSliderField from '@/components/common/form/RangeSliderField';

type Props = {
  className?: string;
  subjects: Subject[];
  groups: CompetencyGroup[];
  filter?: DeepPartial<CompetenciesFilter>;
  onChange?: (value: DeepPartial<CompetenciesFilter>) => void;
  children?: ReactNode;
};

export default function CompetenciesFilterForm(props: Props) {
  const { className, subjects, groups, onChange, filter, children } = props;

  const [filteredGroups, setFilteredGroups] = useState<CompetencyGroup[]>(groups);
  useEffect(() => {
    const { groupId, subjectId } = filter || {};

    if (!subjectId) {
      setFilteredGroups(groups);
    } else {
      // REVIST: subjectId is not a number
      const filtered = groups.filter((g) => g.subjectId === Number(subjectId));
      setFilteredGroups(filtered);

      // NOTE: Reset groupId if not in filtered groups, i.e. if filtered before and then subjectId changes
      if (groupId && !filtered.find((f) => f.id === Number(groupId))) {
        onChange?.({ ...filter, groupId: '' });
      }
    }
  }, [filter, groups, onChange]);

  return (
    <FilterForm className={className} onChange={onChange} filter={filter}>
      <InputField name="search" placeholder="Suche ..." clearable />
      <Dropdown name="subjectId" entries={[{ value: '', label: 'Fach ...' }, ...subjects.map((s) => ({ value: s.id, label: s.label }))]} />
      <Dropdown
        name="groupId"
        fieldOptions={{ required: 'Gruppe wählen' }}
        entries={[{ value: '', label: 'Gruppe ...' }, ...filteredGroups.map((g) => ({ value: g.id, label: g.label }))]}
      />
      <RangeSliderField
        className="w-full"
        name="classLevel"
        min={1}
        max={10}
        step={1}
        minStepsBetweenThumbs={1}
        defaultValue={[1, 10]}
        renderThumbValue
      >
        Klassenstufe
      </RangeSliderField>
      {children}
    </FilterForm>
  );
}

import { tw, variants } from 'classname-variants';
import { SyntheticEvent, useRef } from 'react';
import { JSX } from 'react/jsx-runtime';

import { ValueOf } from '../helper';
import { Icon } from './Icon';
import { cn } from '@/lib/utils';

export type Entity = { id?: number | string };

export type EntityCellRenderer<Data> = (value: ValueOf<Data>, row: Data) => string | JSX.Element;

export type EntityTableColumn<Data> = {
  label: string;
  prop: keyof Data;
  renderer?: EntityCellRenderer<Data>;
  sortable?: boolean;
  sortProperty?: string;
  showSize?: 'all' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  centered?: boolean;
};

type RowClickHandler = (ev: SyntheticEvent<EventTarget>) => void;

type EntityTableProps<Data> = {
  columns: EntityTableColumn<Data>[];
  rows: Data[];
  className?: string;
  getRowOnClickHandler?: (row: Data) => RowClickHandler;
  getColumnSort?: (column: EntityTableColumn<Data>) => { sortOnClickHandler: () => void; orderDir: string | undefined };
  selectedRows?: Data[];
};

const tdClasses = variants({
  base: tw`p-2`,
  variants: {
    head: {
      true: tw`font-bold`,
    },
    sortable: {
      true: tw`cursor-pointer hover:bg-fuxs-orange-light`,
    },
    showSize: {
      all: tw`table-cell`,
      xs: tw`hidden xs:table-cell`,
      sm: tw`hidden sm:table-cell`,
      md: tw`hidden md:table-cell`,
      lg: tw`hidden lg:table-cell`,
      xl: tw`hidden xl:table-cell`,
      '2xl': tw`hidden 2xl:table-cell`,
    },
    centered: {
      true: tw`text-center align-middle`,
    },
  },
});

const trClasses = variants({
  variants: {
    clickable: {
      true: tw`cursor-pointer hover:bg-fuxs-orange-light`,
    },
    selected: {
      false: tw`odd:bg-gray-lightest even:bg-white`,
      true: tw`bg-fuxs-orange`,
    },
  },
});

export default function EntityTable<Data extends Entity>(props: EntityTableProps<Data>) {
  const { className, columns, rows, getRowOnClickHandler, getColumnSort, selectedRows } = props;

  function renderHead(columns: EntityTableColumn<Data>[]) {
    return (
      <thead className="bg-gray-light2">
        <tr>
          {columns.map((column, j) => {
            let sortOnClickHandler = undefined;
            let orderDir = undefined;

            const { showSize = 'all', sortable } = column;

            if (sortable && getColumnSort) {
              ({ sortOnClickHandler, orderDir } = getColumnSort(column));
            }
            const orderIcon = orderDir ? (orderDir === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down') : 'swap_vert';

            return (
              <td
                key={`head-${j}`}
                className={tdClasses({ head: true, sortable: !!sortable && !!sortOnClickHandler, showSize })}
                onClick={sortOnClickHandler}
              >
                <div className="flex flex-row items-center">
                  <span className="grow">{column.label}</span>
                  {sortable && sortOnClickHandler && (
                    <span>
                      <Icon name={orderIcon} className="text-sm" />
                    </span>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      </thead>
    );
  }

  const renderCellValue = (value: ValueOf<Data>, row: Data, renderer?: EntityCellRenderer<Data>) => {
    if (renderer) {
      return renderer(value, row);
    }

    if (value) {
      return String(value);
    }

    return '';
  };

  const tableElement = useRef<HTMLTableElement>(null);

  // NOTE: Prevent to handle bubbled up events, i.e. from Radix DropdownMenu
  const handleRowClick = (rowHandler?: RowClickHandler) => (ev: SyntheticEvent) => {
    if (tableElement.current && ev.target instanceof Node) {
      if (tableElement.current.contains(ev.target)) {
        if (rowHandler) {
          rowHandler(ev);
        }
      }
    }
  };

  return (
    <div className={cn('rounded border-2 border-gray-light overflow-hidden', className)}>
      <table ref={tableElement} className="w-full table-auto">
        {renderHead(columns)}
        <tbody>
          {rows.map((r, i) => {
            const rowOnClick = getRowOnClickHandler ? getRowOnClickHandler(r) : undefined;
            const isSelectedRow = !!selectedRows?.find((sr) => sr.id === r.id);

            return (
              <tr
                className={trClasses({ clickable: !!rowOnClick, selected: isSelectedRow })}
                key={`row-${i}`}
                onClick={handleRowClick(rowOnClick)}
              >
                {columns.map((c, j) => (
                  <td key={`col-${i}-${j}`} className={tdClasses({ showSize: c.showSize || 'all', centered: !!c.centered })}>
                    {renderCellValue(r[c.prop], r, c.renderer)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import { JSX } from 'react';
import { FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

import { Icon } from '@/components/Icon';
import { cn } from '@/lib/utils';

export type DropdownProps<T extends FieldValues> = JSX.IntrinsicElements['select'] & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
  entries: { value: string; label: string }[];
};

export const Dropdown = <T extends FieldValues>(props: DropdownProps<T>) => {
  const { entries, className, name, fieldOptions, children, ...rest } = props;
  const options = entries.map((entry) => (
    <option key={entry.value} value={entry.value}>
      {entry.label}
    </option>
  ));
  const { register } = useFormContext<T>();

  return (
    <div className="relative inline-block w-full md:w-auto">
      <select
        className={cn(
          'block h-10 w-full cursor-pointer appearance-none rounded border-2 py-2 pl-3 pr-6 leading-tight',
          'border-gray-light bg-white  text-black shadow hover:bg-fuxs-orange-light focus:!bg-white',
          'ring-offset-fuxs-orange-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-fuxs-orange-light focus-visible:ring-offset-2',
          'disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        {...register(name, { ...fieldOptions })}
        {...rest}
      >
        {options}
        {children}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <Icon name="keyboard_arrow_down" className="text-xl text-gray-light" />
      </div>
    </div>
  );
};

import { Generated, Insertable, Selectable, Updateable } from 'kysely';
import { Competency } from '../competencies/types.ts';

export enum TaskType {
  Normal = 'normal',
  Project = 'project',
}

export interface TaskTable {
  id: Generated<number>;
  type: TaskType;
  text: string;
  materialId: number;
  image: string;
  description: string;
  hidden?: boolean;
  createdBy: number;
  createdAt?: Date;
}
export type Task = Selectable<TaskTable>;
export type NewTask = Insertable<TaskTable>;
export type TaskUpdate = Updateable<TaskTable>;

export type TaskWithCompetencies = Task & { competencies: Competency[] };

export type TasksFilter = {
  type: TaskType;
  search?: string;
  subjectId?: string;
  groupId?: string;
  competencyId?: string;
  classLevel?: [number, number];
  showHidden?: string;
};

export type TaskCompetenciesTable = {
  taskId: number;
  competencyId: number;
};

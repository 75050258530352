import { ComponentProps } from 'react';
import { FieldPath, FieldValues, PathValue, RegisterOptions, useFormContext } from 'react-hook-form';

import { Slider } from '@/components/ui/slider';

import { cn } from '@/lib/utils';

type SliderFieldProps<T extends FieldValues> = ComponentProps<typeof Slider> & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
};

function getCorrectValue(value: any, defaultValue: any) {
  if (!value) {
    return defaultValue;
  }

  if (Array.isArray(defaultValue) && !Array.isArray(value)) {
    return defaultValue;
  }

  return value;
}

export function RangeSliderField<T extends FieldValues>(props: SliderFieldProps<T>) {
  const { children, className, name, fieldOptions, min, max, defaultValue, ...rest } = props;

  const { register, setValue, watch } = useFormContext<T>();

  const value = watch(name);
  // console.log('RangeSliderField', name, value, Array.isArray(value), defaultValue);

  const onChange = (values: number[]) => {
    // console.log('RangeSliderField.onChange', values);
    setValue(name, values as PathValue<T, FieldPath<T>>, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const correctedValue = getCorrectValue(value, defaultValue);
  // console.log('RangeSliderField.correctedValue', correctedValue, value, defaultValue);

  return (
    <div className={cn('flex gap-2 items-center', className)}>
      <Slider
        className="w-full"
        {...register(name, { ...fieldOptions, value: correctedValue })}
        defaultValue={defaultValue}
        value={correctedValue}
        onValueChange={onChange}
        min={min}
        max={max}
        {...rest}
      />
      {children && (
        <label className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" htmlFor={name}>
          {children}
        </label>
      )}
    </div>
  );
}

export default RangeSliderField;

import { useLoaderData, useNavigate, useParams } from 'react-router';

import type { Plan } from '@backend/model/plans/types';
import type { User } from '@backend/model/users/types';
import type { ServiceResult } from '@backend/service';
import { Button } from '@/components/common';
import { StudentsSelector } from '../components/StudentsSelector';
import { StudentPlans } from './components/StudentPlans';
import Subline from '@/components/common/Subline';

export function StudentsPlansPage() {
  const { studentId } = useParams();
  const { student, plans } = useLoaderData() as { student: User; plans?: ServiceResult<Plan[]> };

  const navigate = useNavigate();
  const onSelectStudent = (student: User) => {
    navigate([location.pathname, student.id].join('/'));
  };

  return (
    <div>
      <Subline count={plans?.total}>Arbeitspläne{studentId ? ` für ${student.displayName}` : ''}</Subline>

      {studentId && (
        <div className="mb-4 flex justify-end">
          <Button link={[location.pathname, 'new'].join('/')}>Neuer Plan</Button>
        </div>
      )}

      {!studentId && <StudentsSelector onSelect={onSelectStudent} />}

      {plans && <StudentPlans plans={plans} />}
    </div>
  );
}

import * as SliderPrimitives from '@radix-ui/react-slider';
import * as React from 'react';

import { cn } from '@/lib/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitives.Root> & { className?: string; renderThumbValue?: boolean }
>(({ className, value, renderThumbValue, ...props }, ref) => {
  return (
    <SliderPrimitives.Root
      className={cn(
        'inline-block relative cursor-pointer rounded-full border-2 transition-colors h-6 w-full',
        'border-gray-light',
        'ring-offset-fuxs-orange-light outline-none',
        'focus-within:ring-2 focus-within:ring-fuxs-orange-light focus-within:ring-offset-2',
        'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
        className
      )}
      value={value}
      {...props}
      ref={ref}
    >
      <SliderPrimitives.Track className={cn('absolute h-5 w-full bg-gray-light2 rounded-full')}>
        <SliderPrimitives.Range className="absolute  h-5 bg-fuxs-orange rounded-full" />
      </SliderPrimitives.Track>

      {(value || Array(1)).map((value, index) => (
        <SliderPrimitives.Thumb
          key={index}
          className={cn(
            'block h-5 w-5 rounded-full shadow-lg bg-gray-medium',
            'outline-none ring-0 focus-visible:ring-4 focus-visible:ring-gray-medium'
          )}
        >
          {renderThumbValue && <span className="text-white text-sm flex items-center justify-center">{value || 1}</span>}
        </SliderPrimitives.Thumb>
      ))}
    </SliderPrimitives.Root>
  );
});

Slider.displayName = SliderPrimitives.Root.displayName;

export { Slider };

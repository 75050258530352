import { QueryFilterForm } from '@/components/common/form/QueryFilterForm';
import { MaterialsFilterFormChildren } from './MaterialsFilterForm';

type Props = {
  className?: string;
};

export default function QueryMaterialsFilterForm(props: Props) {
  const { className } = props;

  return (
    <QueryFilterForm className={className}>
      <MaterialsFilterFormChildren />
    </QueryFilterForm>
  );
}

import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { SubmitHandler } from 'react-hook-form';

import { Dropdown } from '@/components/common/form/Dropdown';
import { EntityForm } from '@/components/common/form/EntityForm';
import { Label } from '@/components/common/form/Label';
import { InputField } from '@/components/common/form/InputField';

type Props = {
  group?: Partial<CompetencyGroup>;
  subjects: Subject[];
  onSubmit: SubmitHandler<CompetencyGroup>;
  onCancel?: () => void;
};

export default function GroupForm(props: Props) {
  const { onCancel, onSubmit, group, subjects } = props;

  return (
    <EntityForm entity={group} onCancel={onCancel} onSubmit={onSubmit} showId>
      <Label name="Fach">
        <Dropdown
          name="subjectId"
          fieldOptions={{ required: 'Fach wählen' }}
          entries={subjects.map((s) => ({ value: s.id, label: s.label }))}
        />
      </Label>
      <Label name="Name">
        <InputField name="label" placeholder="Name" />
      </Label>
    </EntityForm>
  );
}

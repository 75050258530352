import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import type { TaskType, TaskWithCompetencies } from '@backend/model/tasks/types';
import { useLoaderData } from 'react-router';

import { Button } from '@/components/common';
import { NextCloudImage } from '@/components/common/NextCloudImage';
import EntityTable from '@/components/EntityTable';
import { dateTimeRenderer } from '@/helper';

import { getCompetencyColumns } from '../../curriculum/competencies/utils';
import { MaterialComponent } from '../materials/components/MaterialComponent';
import { TASK_TEXTS } from './utils';
import OpenInOverlay from '@/components/common/OpenInOverlay';

export function TaskPage({ type }: { type: TaskType }) {
  const { task, subjects, groups } = useLoaderData() as {
    task: TaskWithCompetencies;
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const texts = TASK_TEXTS[type];

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">{texts.itemTitle}</h1>
      <div className="mb-4 flex justify-end">
        <Button link={[location.pathname, 'edit'].join('/')}>Editieren</Button>
      </div>

      <div className="mb-2 flex w-full flex-col gap-1">
        {/* <div>
          <b>ID</b>: {task.id}
        </div> */}
        <div>
          <b>{texts.mainLabel}</b>: {task.text}
        </div>
        {task.description && (
          <div>
            <b>Beschreibung</b>: {task.description}
          </div>
        )}
        {task.materialId && (
          <div className="mb-2">
            <b>Material</b>:{' '}
            <MaterialComponent className="border-2 border-gray-light bg-gray-lightest p-2 rounded" material={task.material} />
          </div>
        )}
        {task?.image && (
          <div className="flex justify-around mb-1">
            <OpenInOverlay
              preview={
                <NextCloudImage className="max-h-[25vh]" file={String(task.image)} alt={String(task.image)} width="600" height="600" />
              }
            >
              <NextCloudImage className="w-full" file={task.image} />
            </OpenInOverlay>
          </div>
        )}
        {task.competencies && (
          <div className="mb-2">
            <b>Kompetenzen</b>:
            <EntityTable columns={getCompetencyColumns(subjects, groups)} rows={task.competencies} />
          </div>
        )}
        <div>
          <b>Erstellt am</b>: {dateTimeRenderer(task.createdAt)}
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { FieldPath, FieldValues, PathValue, useFormContext } from 'react-hook-form';

import type { ServiceResult } from '../../../../../backend/src/service';
import { getNewColumnSort } from '../../../helper';
import { useDebounce } from '../../../hooks/useDebounce';
import EntityTable, { Entity, EntityTableColumn } from '../../EntityTable';
import { Icon } from '../../Icon';
import { Pagination } from '../../Pagination';
import { Button } from '../Button';
import { ModalPortal } from '../ModalPortal';
import { InputField } from './InputField';

export type ItemQuery = {
  page: number;
  orderBy?: string;
};

export type ChooserProps<T extends FieldValues, I, F> = JSX.IntrinsicElements['input'] & {
  name: FieldPath<T>;
  transientProp?: FieldPath<T>;
  title?: string;
  itemsRenderer?: (items: I[]) => JSX.Element;
  choosenProp: keyof I;
  filter?: F;
  filterForm?: JSX.Element;
  defaultOrderBy?: string;
  itemQueryFn: (query: ItemQuery, filter?: F) => Promise<ServiceResult<I[]>>;
  pageSize?: number;
  chooserColumns: EntityTableColumn<I>[];
  onShow?: () => void;
  chooseMax?: number;
  newButton?: JSX.Element;
};

export function Chooser<T extends FieldValues, I extends Entity, F>(props: ChooserProps<T, I, F>) {
  const {
    className,
    name,
    transientProp,
    title,
    itemsRenderer,
    filter,
    filterForm,
    defaultOrderBy = 'id:desc',
    choosenProp,
    itemQueryFn,
    pageSize = 25,
    chooserColumns,
    onShow,
    chooseMax = 1,
    newButton,
  } = props;

  const [showChooser, setShowChooser] = useState<boolean>(false);

  const showModal = async () => {
    document.body.style.overflow = 'hidden';
    changePage(1);
    setShowChooser(true);
    onShow?.();
  };

  const hideModal = () => {
    setShowChooser(false);
    document.body.style.overflow = 'unset';
  };

  const [query, setQuery] = useState<ItemQuery>({ page: 1, orderBy: defaultOrderBy });
  const [items, setItems] = useState<ServiceResult<I[]>>({ result: [], total: 0 });

  const debouncedItemSearch = useDebounce(async () => {
    const result = await itemQueryFn(query, filter);
    setItems(result);
  }, 250);

  useEffect(() => {
    if (showChooser) {
      debouncedItemSearch();
    }
  }, [showChooser, query, filter, debouncedItemSearch]);

  // REVISIT: Something stinks here in the way how we handle the filter triggering the query

  const changePage = (page: number) => {
    setQuery({ ...query, page });
  };

  useEffect(() => {
    // NOTE: When filter is chaning set the page to start at the beginning
    changePage(1);
  }, [filter]);

  const { setValue, getValues } = useFormContext();
  const value = getValues(name);

  // NOTE: Smelly hack in case we do have a non-empty transient prop, but no value yet assigned
  useEffect(() => {
    if (!value) {
      if (transientProp) {
        const transientValue = getValues(transientProp);
        if (transientValue) {
          if (Array.isArray(transientValue) && transientValue.length > 0) {
            setValue(
              name,
              transientValue.map(({ id }: { id: number | string }) => id),
              {
                shouldValidate: false,
                shouldDirty: true,
                shouldTouch: true,
              }
            );
          } else {
            setValue(name, transientValue, {
              shouldValidate: false,
              shouldDirty: true,
              shouldTouch: true,
            });
          }
        }
      }
    }
  }, [value]);

  const getColumnSort = (column: EntityTableColumn<I>) => {
    const { currentDir, newOrderBy } = getNewColumnSort(column, query.orderBy, defaultOrderBy);
    return {
      sortOnClickHandler: () => setQuery({ ...query, orderBy: newOrderBy }),
      orderDir: currentDir,
    };
  };

  const getDefaultSelected = () => {
    if (transientProp) {
      const transientValue = getValues(transientProp);
      if (!transientValue) {
        return [];
      }
      return Array.isArray(transientValue) ? transientValue : [transientValue];
    }
    if (value) {
      return Array.isArray(value) ? value?.map((id: number) => ({ id })) : [{ id: value }];
    }
    return [];
  };

  // REVISIT: This needs to be tweaked once we save and load the selection
  const [selected, setSelected] = useState<I[]>(getDefaultSelected());

  const getRowOnClickHandler = (item: I) => () => {
    if (chooseMax === 1) {
      setSelected([item]);
      if (transientProp) {
        console.log('Setting transientProp', transientProp, item as never);
        setValue(transientProp, item as never, {
          shouldValidate: false,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      setValue(name, item[choosenProp] as PathValue<T, FieldPath<T>>, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      hideModal();
      return;
    }

    if (selected.length >= chooseMax) {
      return;
    }

    if (selected.find((i) => i.id === item.id)) {
      setSelected(selected.filter((i) => i.id !== item.id));
      return;
    }
    setSelected([...selected, item]);
  };

  const getDeleteRowHandler = (item: I) => () => {
    setSelected(selected.filter((i) => i.id !== item.id));
  };

  const confirmSelection = () => {
    setValue(name, selected.map((s) => s[choosenProp]) as PathValue<T, FieldPath<T>>, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (transientProp) {
      setValue(transientProp, selected as never, {
        shouldValidate: false,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    hideModal();
  };

  return (
    <>
      <div className={`flex flex-col gap-4 md:flex-row ${className}`}>
        <InputField name={name} className="inline-block grow" readOnly disabled />

        {value && !(Array.isArray(value) && value.length === 0) && (
          <Button
            onClick={() => {
              setSelected([]);
              setValue(name, (chooseMax === 1 ? null : []) as never);
              if (transientProp) {
                setValue(transientProp, null as never);
              }
            }}
          >
            <Icon className="mr-1 text-2xl" name="delete" /> Auswahl löschen
          </Button>
        )}

        {newButton}

        <Button onClick={showModal}>{title || 'Auswählen'}</Button>
      </div>

      {itemsRenderer && selected.length > 0 && <div className="mt-4">{itemsRenderer(selected)}</div>}

      {showChooser && (
        <ModalPortal>
          <div className="fixed left-0 top-0 size-full overflow-scroll p-4 backdrop-blur">
            <div className="mb-4 flex flex-col-reverse gap-2 sm:flex-row">
              {selected.length > 0 ? <h2 className="text-2xl font-bold">Ausgewählt</h2> : filterForm}
              <div className="grow" />
              <Button onClick={hideModal}>Schließen</Button>
            </div>

            {selected.length >= 1 && (
              <div className="mb-4">
                <EntityTable columns={chooserColumns} rows={selected} className="mb-4" getRowOnClickHandler={getDeleteRowHandler} />
                <div className="mb-8 flex flex-row">
                  <Button onClick={() => setSelected([])}>❌ Auswahl aufheben</Button>
                  <div className="grow" />
                  <Button onClick={confirmSelection}>✅ Übernehmen</Button>
                </div>
              </div>
            )}

            {/* NOTE: Renders the filter form here when having a multi selection */}
            {selected.length > 0 && filterForm}

            <h2 className="my-4 text-2xl font-bold">
              {title || 'Auswählen'} {items.total && <>({items.total})</>}
            </h2>
            <div>
              {!items.result.length ? (
                <p>Nichts zum auswählen gefunden</p>
              ) : (
                <>
                  <EntityTable
                    columns={chooserColumns}
                    rows={items.result}
                    className="mb-8"
                    getColumnSort={getColumnSort}
                    getRowOnClickHandler={getRowOnClickHandler}
                    selectedRows={Array.isArray(selected) ? selected : [selected]}
                  />
                  <div className="flex justify-center">
                    <Pagination page={query.page} pageSize={pageSize} total={items.total} onChangePage={changePage} />
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalPortal>
      )}
    </>
  );
}

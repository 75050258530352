import type { CompetenciesFilter, Competency } from '@backend/model/competencies/types';
import type { User } from '@backend/model/users/types';
import type { ServiceResult } from '@backend/service';
import { useEffect } from 'react';
import { useLoaderData, useNavigate, useRevalidator } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { api } from '@/api';
import { Dropdown } from '@/components/common/form/Dropdown';
import { QueryPagination } from '@/components/QueryPagination';
import { COMPETENCIES_PAGE_SIZE } from '@/curriculum/competencies/CompentenciesListPage';
import QueryCompetenciesFilterForm from '@/curriculum/competencies/components/QueryCompetenciesFilterForm';
import { findGroup, findSubject } from '@/curriculum/competencies/utils';
import { SubjectsAndGroups } from '@/curriculum/loaders';
import { getFilter } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import { useUpdateMessageState } from '@/MessageProvider';
import { useStack } from '@/StackProvider';

import { StudentsSelector } from '../components/StudentsSelector';
import CompetencyCard from './components/CompetencyCard';
import { Sub } from '@radix-ui/react-dropdown-menu';
import Subline from '@/components/common/Subline';

export function StudentsCompetenciesPage() {
  const loaderData = useLoaderData() as SubjectsAndGroups & { competencies: ServiceResult<Competency[]>; student: User };
  const { student, subjects, groups, competencies: { result: items, total } = {} } = loaderData;

  const navigate = useNavigate();

  const onSelectStudent = (student: User) => {
    navigate([location.pathname, student.id].join('/'));
    return;
  };

  const query = useQuery();
  const page = Number(query.get('page')) || 1;

  const [searchParams, setSearchParams] = useSearchParams();
  const filter = getFilter<CompetenciesFilter>(searchParams) || {};
  const competencyId = filter.competencyId;

  useEffect(() => {
    if (competencyId) {
      searchParams.set('filter', JSON.stringify({ competencyId }));
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const clearFilter = () => {
    searchParams.set('filter', JSON.stringify({ competencyId: null }));
    setSearchParams(searchParams);
  };

  const revalidator = useRevalidator();
  const updateMessageState = useUpdateMessageState();
  const onChangeRating = async (competencyId: number, rating: number) => {
    if (!student) {
      throw new Error('Student not selected or loaded');
    }
    try {
      await api.uspertCompetencyRating(student.id, competencyId, rating);
      revalidator.revalidate();
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  const { stack, stackPop } = useStack();
  const stackedPlan = stack.length > 0 && stack[stack.length - 1];
  const backToPlan = () => {
    if (stackedPlan) {
      const { pathname, search, state } = stackedPlan;
      stackPop(); // Pop from stack
      navigate(pathname + (search || ''), { state });
    }
  };

  // REVISIT: There are too many re-renders which should be fixed at some point
  // console.log(items);

  return (
    <div>
      <Subline count={total}>Kompetenzen{student ? ` von ${student.displayName}` : ''}</Subline>

      {!student && <StudentsSelector onSelect={onSelectStudent} />}

      {student && (
        <>
          {!competencyId && (
            <QueryCompetenciesFilterForm className="mb-8 grow flex-col sm:flex-row" subjects={subjects} groups={groups}>
              <Dropdown
                name="selection"
                entries={[
                  { value: 'all', label: 'Alle' },
                  { value: 'rated', label: 'Nur eingeschätzte' },
                  { value: 'unrated', label: 'Nur uneingeschätzte' },
                  { value: 'touched', label: 'Nur mit Aufgaben' },
                  { value: 'confirmed', label: 'Nur mit bestätigten Aufgaben' },
                  { value: 'untouched', label: 'Noch ohne Aufgaben' },
                ]}
              />
            </QueryCompetenciesFilterForm>
          )}

          {competencyId && (
            <>
              <p className="flex gap-1">
                Zur Zeit ist auf eine Kompetenz gefiltert.
                <button className="mb-4 text-fuxs-orange-dark transition-colors hover:text-fuxs-orange" onClick={clearFilter}>
                  Filter zurücksetzen
                </button>
                {stackedPlan && (
                  <>
                    oder
                    <button className="mb-4 text-fuxs-orange-dark transition-colors hover:text-fuxs-orange" onClick={backToPlan}>
                      zurück zum Plan
                    </button>
                  </>
                )}
              </p>
            </>
          )}

          {!items!.length ? (
            <p>Keine Kompetenzen gefunden</p>
          ) : (
            <>
              {items?.map((item) => {
                const competency = { ...item, subject: findSubject(subjects, item.subjectId), group: findGroup(groups, item.groupId) };
                return <CompetencyCard key={item.id} competency={competency} onChangeRating={onChangeRating} />;
              })}
              <div className="flex justify-center">
                <QueryPagination page={page} pageSize={COMPETENCIES_PAGE_SIZE} total={total} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

import { useLoaderData } from 'react-router';

import type { Subject } from '@backend/model/subjects';
import { Button } from '@/components/common';
import { NextCloudImage } from '@/components/common/NextCloudImage';
import OpenInOverlay from '@/components/common/OpenInOverlay';

export function SubjectPage() {
  const subject = useLoaderData() as Subject;

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Fach</h1>
      <div className="mb-4 flex justify-end">
        <Button link={[location.pathname, 'edit'].join('/')}>Editieren</Button>
      </div>

      <div className="flex w-full flex-col gap-1">
        {/* <div>ID: {subject.id}</div> */}
        <p className="text-4xl font-bold">{subject.label}</p>
        <p>
          <b>Kürzel</b>: {subject.shortCode}
        </p>
        {subject?.image && (
          <OpenInOverlay
            preview={
              <NextCloudImage className="max-h-[25vh]" file={String(subject.image)} alt={String(subject.image)} width="600" height="600" />
            }
          >
            <NextCloudImage className="w-full" file={subject.image} />
          </OpenInOverlay>
        )}
      </div>
    </div>
  );
}
